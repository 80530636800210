body {
  margin: 0;
  padding: 0;
}

.app {
  width: 100vw;
  height: 100vh;
}


.login-form {
margin: 20px;
}

.fixed-height-autocomplete .MuiAutocomplete-tag {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fixed-height-autocomplete .MuiAutocomplete-popup {
  max-height: 200px; /* Adjust the height value as per your requirement */
  overflow-y: auto;
}
